@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Thin';
    src: url('../fonts/roboto-thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('../fonts/JosefinSlab-Regular.ttf') format('truetype'),
    url('../fonts/JosefinSlab-Thin.ttf') format('truetype');
}

body {
    font-family: "Roboto", "Josefin Slab", sans-serif;
}

.bg-page {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.7;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.bg-footer {
    position: absolute;
    right: 0;
    bottom: 0;
}

.footer-logo {
    background-image: url("../avetta.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    min-height: 3rem;
}

.logo {
    max-width: 20rem;
    max-height: 20rem;
}

.hit {
    height: 13.5rem;
    background-color: #FFF;
    border-radius: 0.25rem;
}

.hit .worker-image {
    width: 10rem !important;
    float: left;
}

.hit .worker-details {
    /* FIXME: Don't hardcode this. Just temporary for static iPad resolution */
    max-width: 22.5rem;
}

.hit-worker-name {
    font-size: 1.75rem;
}

.hit-company-name {
    font-size: 0.75rem;
    color: #696969;
}

.text-black {
    color: #212529 !important;
}

.no-outline:focus {
    outline: none;
}

.btn {
    border: 0;
    border-radius: 0;
    text-decoration: none;
}

.btn-huge {
    padding: 1.5rem;
    font-size: 2rem;
    line-height: 1.5em;
    min-width: 8em;
}

.btn-header {
    height: calc(5.625rem - 1rem); /* 5.625rem equals 90pxs */
    color: black !important;
}

.btn-qr-code {
    width: 4rem;
    border-radius: 0 0.25rem 0.25rem 0 !important;
    box-shadow: none !important;
}

.row .col-sm-3 .btn-admin {
    border-radius: 50%;
    width: 12rem;
    height: 12rem;
}

.btn-admin-label,
.btn-search-workers {
     font-size: 1.5rem;
 }

.btn-menu {
    color: white;
}

.btn-background-job {
    height: 3.5rem;
}

.btn-connectivity {
    color: white;
}

.btn-nav {
    font-size: 2rem;
}

.btn-back {
    left: 4rem;
    bottom: 8rem;
}

.btn-next {
    right: 4rem;
    bottom: 8rem;
}

.btn-back-worker-hits {
    left: 0.5rem;
    top: calc(50% - 101px);
}

.btn-next-worker-hits {
    right: 0.5rem;
    top: calc(50% - 101px);
}

.btn-worker-id {
    top: 0.3rem;
    right: 1rem;
}

.btn-worker-id svg {
    font-size: 2.5rem;
}

.btn-logpoint, .btn-logpoint:disabled, .btn-logpoint:hover, .btn-logpoint:focus {
    color: #FFFFFF;
}

.btn-transparent, .btn-transparent:disabled, .btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus {
    background-color: transparent !important;
}

.btn-logpoint, .btn-logpoint-secondary {
    height: 5rem;
    border-radius: 0.25rem;
    box-shadow: 0.25rem 0.25rem 0.5rem #BBB;
}

.btn-logpoint-secondary {
    background-color: #FFF;
}

.btn-logpoint span, .btn-logpoint-secondary span {
    top: 0;
    font-size: 1.75rem;
    line-height: 1.75rem;
}

.btn-logpoint-secondary:not(:disabled):not(.disabled).active, .btn-logpoint-secondary:not(:disabled):not(.disabled):active,
.show > .btn-logpoint-secondary.dropdown-toggle, .btn-logpoint-secondary.focus, .btn-logpoint-secondary:focus,
.btn-logpoint-secondary.hover, .btn-logpoint-secondary:hover {
    background-color: #FFF;
}

.btn-logpoint-secondary.disabled, .btn-logpoint-secondary:disabled {
    background-color: #FFF;
}

.btn-transparent {
    box-shadow: none;
}

.btn-sign-in-out {
    height: 6.5rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.21);
}

.btn-sign-in-out span {
    line-height: 6.5rem;
}

.btn-modal,
.btn-card {
    box-shadow: none;
    height: 4rem;
}

.btn-report,
.btn-home {
    height: 5rem;
    width: 5rem;
    bottom: 2rem;
    left: 2rem;
    border-radius: 0.25rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.21);
}

.bg-lighter, .bg-lighter:hover, .bg-lighter:focus {
    background-color: #DFDFDF !important;
}

.logpoint-form-control {
    font-size: 1.25rem;
    height: 5rem;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
    border-color: #D8D8D8;
    border-width: 1px;
}

.logpoint-form-control::placeholder {
    font-size: 1.25rem;
    font-weight: bold;
    color: #C9C9C9;
}

.mt-20-perc {
    margin-top: 20%;
}

.chip-container {
    overflow: auto;
    overflow-x: hidden;
    max-height: 25vh;
}

.activities .chip-container::-webkit-scrollbar {
    width: 0;  /* Hide scrollbar */
}

.chip {
    border: 1px solid gray;
    background-color: rgba(128, 128, 128, 0.5);
    padding: 1.5em;
    width: 36em;
}

.bg-half-transparent {
    background-color: rgba(128, 128, 128, 0.5);
}

.chip h4 {
    width: 80%
}

.spinner-background {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.pegasus-spinner {
    top: 50%;
    left: 50%;
    z-index: 3;
}

.pegasus-spinner .alert {
    width: 16em;
    left: calc(50% - 16em);
    top: -2em;
    font-size: 1.5em;
}

.current-page-number {
    width: 4rem;
    border-radius: 0;
}

.lp-name
 {
    font-size: 1.125rem;
    line-height: 4.625rem;
}

.card-body table {
    margin-bottom: 0;
}

.spinner-center {
    margin-left: calc(50% - 1.25rem);
}

.battery-percent {
    font-size: 1rem;
    transform: translate(0, -1.8rem);
}

.row.visitor-or-forgot-id {
    height: 4rem;
}

.vertical-separator {
    border-left: 0.125rem solid #AAA;
    height: 25vh;
    position: absolute;
    top: calc(50% - 6.5rem);
    left: calc(50% - 0.125rem);
}

.main-container {
    z-index: 1;
}

.main {
    /* This needs to be updated if the header or footer heights change */
    height: calc(100% - 8.625rem);
}

.access-instructions, .sub-heading {
    font-size: 1.5rem;
    color: #696969;
}

.mt-10rem {
    margin-top: 10rem;
}

.w-35 {
    width: 35%;
}

.max-w-35 {
    max-width: 35%;
}

.w-60 {
    width: 60%;
}

.obfuscated-details {
    width: fit-content;
    border-radius: 0.25rem;
    background-color: #AAAAAA;
}

.carousel-control-prev,
.carousel-control-next {
    filter: invert(100%); /* Flips the icon from being white to black */
}

.input-label {
    color: #898989;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: unset;
}

.carousel-control {
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.carousel-control-right {
    right: 0;
}

.worker-silhouette {
    color: #AAAAAA;
}

.card-body {
    box-shadow: 0.125rem 0.125rem 0.25rem #BBB;
    border-radius: 0.25rem;
}

.min-height-10rem {
    min-height: 10rem;
}

.bg-header {
    min-height: 90px;
}

.onsite-now-report {
    max-height: 70vh;
    overflow: auto;
}
.company-name,
.person-name {
    min-width: 12rem;
}

.worker-id {
    min-width: 6rem;
}

.contact-number {
    font-size: 0.75rem;
}
